<form [formGroup]="form">

  <div *ngIf="!description" class="d-flex flex-row justify-content-center align-items-center">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <ng-container *ngIf="polName">
        <h1 mat-dialog-title><span class="info-title-dialog">Polygon's name: </span><span class="title-dialog-bold">{{polName}}</span></h1>

      </ng-container>

      <ng-container *ngIf="compareObj; else notCompare">

        <h1 mat-dialog-title><span class="info-title-dialog">First dataset: </span><span class="title-dialog-bold">{{compareObj.firstDataset.name.title}}</span></h1>
        <h1 mat-dialog-title><span class="info-title-dialog">Second dataset: </span><span class="title-dialog-bold">{{compareObj.secondDataset.name.title}}</span></h1>
      </ng-container>

      <ng-template #notCompare>
        <h1 mat-dialog-title><span class="info-title-dialog">Dataset's name: </span><span class="title-dialog-bold">{{datasetName}}</span></h1>

      </ng-template>

    </div>
    <ng-container *ngIf="!polName && openGraph">
      <!-- <h1 mat-dialog-title><span class="info-title-dialog">Lat: </span><span class="title-dialog-bold">{{latlng.lat.toFixed(5)}}</span>, <span class="info-title-dialog">Lng: </span><span class="title-dialog-bold">{{latlng.lng.toFixed(5)}}</span></h1> -->
      <h1 mat-dialog-title><span class="info-title-dialog">Lat: </span><span class="title-dialog-bold">{{parseInFloatLat()}}</span>, <span class="info-title-dialog">Lng: </span><span class="title-dialog-bold">{{parseInFloatLng()}}</span></h1>

    </ng-container>

    <button *ngIf="prodDev === 'dev' && openGraph" mat-raised-button color="primary" (click)="showInfo()">Info</button>

  </div>
  <!-- <div *ngIf="!polName"> -->
  <!-- </div> -->
  <h1 *ngIf="description" mat-dialog-title class="title-dialog-bold">{{description}}</h1>
  <!-- <h1 *ngIf="!description && openGraph" mat-dialog-title class="title-dialog-bold">{{datasetName}}</h1> -->
  <!-- <div *ngIf="success; else ok"> -->
  <div *ngIf="success">
    <!-- <div mat-dialog-content *ngIf="info === false; else infoDialog"> -->
    <div mat-dialog-content id="noInfo">

      <div #metadataTable *ngIf="!openGraph; else graph">
        <!-- <mat-spinner *ngIf="spinnerLoading" fxLayoutAlign="center center"></mat-spinner> -->
        <mat-spinner *ngIf="spinnerLoading" class="col-12 d-flex flex-row justify-content-center align-items-center"></mat-spinner>
        <!-- <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" *ngIf="spinnerLoading">
          <div class="progress-bar progress-bar-striped progress-bar-animated" [ngStyle]="{'width': progressWidth}">
            {{progressWidth}}
          </div>
        </div> -->
        <table mat-table [dataSource]="dataSource">

          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index">
            <th mat-header-cell *matHeaderCellDef> {{column.charAt(0).toUpperCase() + column.slice(1)}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
          aria-label="Select page of periodic elements">

        </mat-paginator>
      </div>

      <ng-template #graph>
        <div #graphDiv *ngIf="!description">
          <!-- <mat-spinner style="margin: auto !important;" *ngIf="spinnerLoading"></mat-spinner> -->
          <div class="col-12 d-flex flex-row justify-content-center align-items-center mt-5" *ngIf="spinnerLoading">
            <h3>Loading...</h3>

          </div>
          <div class="progress mt-2" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" *ngIf="spinnerLoading" style="height: 40px;">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary h-100" [ngStyle]="{'width': progressWidth}">
              {{progressWidth}}
            </div>
          </div>


          <!-- <ng-container *ngIf="!spinnerLoading"> -->
          <!-- <mat-tab-group *ngIf="!spinnerLoading" class="tab-group"> -->
          <mat-tab-group class="tab-group" [ngStyle]="{'visibility': spinnerLoading ? 'hidden' : 'visible'}">

            <mat-tab label="Graph" class="tab-graph col-12">
              <!-- <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 55px;"> -->
              <!-- <div class="col-12 d-flex flex-row justify-content-between align-items-center" style="height: 55px;"> -->
              <div class="col-12 row justify-content-between" style="height: 55px;">

                <!-- <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"> -->
                <!-- <div class="col-4 d-flex flex-row justify-content-start align-items-center gap-2"> -->
                <div *ngIf="!compareObj" [ngClass]="[polygon ? 'col-5' : 'col-3', 'd-flex', 'flex-row', 'justify-content-start', 'align-items-center', 'gap-2']">
                  <!-- <div> -->
                  <!-- <h4>Select graph</h4> -->

                  <mat-form-field appearance="fill" class="col" *ngIf="!compareObj">
                    <mat-label>Time scale</mat-label>
                      <mat-select formControlName="operationSel" (selectionChange)="showStatPointSelected(polygon)">
                        <mat-option *ngFor="let o of optionTimeScale" [disabled]="removeAnnualCycle(o)"
                          [matTooltip]="'Disabled in this period'" [matTooltipDisabled]="!removeAnnualCycle(o)"
                          [value]="o.value">{{o.label}}</mat-option>
                      </mat-select>

                  </mat-form-field>

                  <ng-container *ngIf="polygon">
                    <!-- STATISTICS PER I POLIGONI -->
                    <!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"> -->
                    <div class="col-5 d-flex flex-row justify-content-center align-items-center gap-1">
                      <mat-form-field appearance="fill" class="col-12">
                        <mat-label>Statistics</mat-label>
                        <mat-select formControlName="statisticSel">
                          <mat-option *ngFor="let s of optionStatistics" [matTooltip]="'Disabled on this time scale'" [matTooltipDisabled]="!disableStatistics(s)" [disabled]="disableStatistics(s)" [value]="s.value">{{s.label}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <!-- {{form.get('statisticSel')?.value | json}} -->
                    </div>
                    <button class="col-2" mat-raised-button color="primary" (click)="sendSelGraphPoly(); showStat()">Update</button>
                  </ng-container>

                </div>
                <!-- <div fxFlex="60" fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 10px;"> -->
                <ng-container *ngIf="compareObj; else normalStat">
                  <!-- DROPDOWN -->
                  <!-- <div class="btn-group col d-flex flex-row justify-content-start align-items-center">
                    <button type="button" class="btn btn-primary dropdown-toggle d-flex flex-row justify-content-center align-items-center text-center" style="max-width: 110px; height: 60%;" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Stats
                    </button>
                    <div class="dropdown-menu w-100 p-3">
                      <div class="link-list-wrapper">
                        <ul class="link-list">
                          <li class="col d-flex flex-row justify-content-center align-items-center p-2">Title first dataset</li>
                          <li class="col d-flex flex-row justify-content-between align-items-center p-2">
                            <span>Mean: <br> mean1</span>
                            <span>Median: <br> median1</span>
                            <span>Stddev: <br> stdev1</span>
                            <span>Trend/Yr: <br> trend/yr1</span>
                          </li>
                          <li class="col d-flex flex-row justify-content-center align-items-center p-2">Title second dataset</li>
                          <li class="col d-flex flex-row justify-content-between align-items-center p-2">
                            <span>Mean: <br> mean2</span>
                            <span>Median: <br> median2</span>
                            <span>Stddev: <br> stdev2</span>
                            <span>Trend/Yr: <br> trend/yr2</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> -->
                  <div class="col d-flex flex-row justify-content-around align-items-center" *ngIf="showStatistic">
                      <span matTooltip="Mean difference average between the second and the first dataset">Mean diff. avg D2 - D1: <br> {{stats.meanDiffAvg}}</span>
                      <span matTooltip="Mean absolute difference average">Mean abs. diff. avg: <br> {{stats.meanDiffAvgAbs}}</span>
                      <span matTooltip="Root mean square difference square root">RMS diff.: <br> {{stats.rootSquaredDiff}}</span>
                  </div>

                </ng-container>
                <ng-template #normalStat>

                  <div class="col d-flex flex-row gap-sm-0 gap-md-1 gap-lg-4 justify-content-xl-between align-items-center" *ngIf="showStatistic">
                    <span>Mean: <br> {{meanValue}}</span>
                    <span>Median: <br> {{medianValue}}</span>
                    <span>Stddev: <br> {{stdevValue}}</span>
                    <span>Trend/Yr: <br> {{trendValue}}</span>
                    <button mat-raised-button color="primary" (click)="calcStatistics()">Calc</button>
                  </div>
                </ng-template>

                <!-- <div fxFlex fxLayout="row" fxLayoutAlign="end center"> -->
                <ng-container *ngIf="!compareObj">
                  <div class="col-1 d-flex flex-row justify-content-end align-items-center" *ngIf="statistic !== 'boxPlot'">
                    <section class="example-section">
                      <mat-checkbox class="example-margin" formControlName="enableArea">Enable area</mat-checkbox>
                    </section>
                  </div>
                </ng-container>

              </div>
              <ng-container *ngIf="compareObj; else noConfronto">
                <app-canvas-graph-compare [compareObj]="compareObj" (dataTimeExport)="addDataTimeExport($event)"  (compareStats)="compareStats($event)"
                  (dataTablePolygon)="dataTablePolygon($event)" (spinnerLoadingChild)="spinnerLoadingChild($event)"
                  (statisticCalc)="statisticCalc($event)" (description)="descriptionError($event)"
                  class="canvas-graph" [dataset]="dataset" [idMeta]="datasetId" [latlng]="latlng" [variable]="variable"
                  [range]="range" [isIndicator]="isIndicator" [polygon]="polygon"
                  [operation]="polygon ? operation : this.form.get('operationSel')?.value" [statistic]="statistic"
                  [extraParam]="extraParamExport" [enableArea]="form.get('enableArea')?.value"
                  [circleCoords]="circleCoords"
                  [context]="'one'">
                </app-canvas-graph-compare>
              </ng-container>
               <ng-template #noConfronto>
                <app-canvas-graph (dataTimeExport)="addDataTimeExport($event)"  (meanMedianStdev)="meanMedianStdev($event)" (description)="descriptionError($event)"
                  (dataTablePolygon)="dataTablePolygon($event)" (spinnerLoadingChild)="spinnerLoadingChild($event)"
                  (statisticCalc)="statisticCalc($event)" (progressBar)="progressBar($event)"
                  class="canvas-graph" [dataset]="dataset" [idMeta]="datasetId" [latlng]="latlng" [variable]="variable"
                  [range]="range" [isIndicator]="isIndicator" [polygon]="polygon"
                  [operation]="polygon ? operation : this.form.get('operationSel')?.value" [statistic]="statistic"
                  [extraParam]="extraParamExport" [enableArea]="form.get('enableArea')?.value"
                  [circleCoords]="circleCoords"
                  [dimUnit]="dimUnit"
                  [context]="'one'">
                </app-canvas-graph>

              </ng-template>

              <!-- <ng-template matTabContent> -->

              <!-- </ng-template> -->
            </mat-tab>
            <mat-tab label="Table" *ngIf="!compareObj">

              <table mat-table [dataSource]="dataSource">

                <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index">
                  <th mat-header-cell *matHeaderCellDef> {{column.charAt(0).toUpperCase() + column.slice(1)}} </th>
                  <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                aria-label="Select page of periodic elements">

              </mat-paginator>

            </mat-tab>
            <!-- <mat-tab label="Export" fxFlex fxLayout="row" fxLayoutAlign="center center" class="export-tab"> -->
            <mat-tab label="Export" class="export-tab col-12 d-flex flex-row justify-content-center align-items-center" *ngIf="!compareObj">
              <!-- QUI CI VA IL CODICE PER EXPORT -->
              <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style="max-height: 100% !important; overflow: hidden !important;"> -->
              <div class="col-12 d-flex flex-column justify-content-center align-items-center gap-2 mh-100" style="overflow: hidden !important;">

                <!-- <div fxFlex fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%;"> -->
                <div class="d-flex col-12 flex-row flex-wrap justify-content-center align-items-center w-100">
                  <!-- <span fxFlex="100" fxLayoutAlign="center center"><b>Time range</b></span> -->
                  <span class="col-12 d-flex flex-row justify-content-center align-items-center"><b>Time range</b></span>
                  <!-- <ngx-slider fxFlex="100" [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider> -->
                  <ngx-slider class="col-12" [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
                </div>

                <!-- <div *ngIf="dataset.dimensions > 3" fxFlex fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%;"> -->
                <div *ngIf="dataset.dimensions > 3 && dataset.wms_url !== '' " class="d-flex flex-row flex-wrap col-12 justify-content-center align-items-center">
                  <!-- <span fxFlex="100" fxLayoutAlign="center center"><b>{{extraParamExport.name}}</b></span> -->
                  <span class="col-12 d-flex justify-content-center align-items-center"><b>{{extraParamExport.name}}</b></span>
                  <!-- <ngx-slider fxFlex="100" [(value)]="minRange" [(highValue)]="maxRange" [options]="optionsExtra"></ngx-slider> -->
                  <ngx-slider class="col-12" [(value)]="minRange" [(highValue)]="maxRange" [options]="optionsExtra"></ngx-slider>
                </div>
                <div class="menu-var">
                  <h6 class="text-center">Variable</h6>
                  <mat-form-field appearance="fill">
                    <mat-label>Please select a variable</mat-label>
                    <mat-select formControlName="varSelected" multiple>
                      <mat-option *ngFor="let v of arrayVariable" [value]="v">{{v}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- {{form.get('varSelected')?.value | json}} -->
                </div>
                <div class="menu-var">
                  <h6 class="text-center">File Type</h6>
                  <mat-form-field appearance="fill">
                    <mat-label>Select the type of file</mat-label>
                    <mat-select formControlName="typeSel">
                      <mat-option *ngFor="let t of typeOfExport" [value]="t.type">{{t.label}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <button mat-raised-button color="primary" (click)="exportData(form.get('typeSel')?.value)">Export</button> -->
                <button mat-raised-button color="primary" (click)="exportData(this.form.get('typeSel')?.value)"
                  [disabled]="form.invalid">Export</button>
              </div>

            </mat-tab>
          </mat-tab-group>
          <!-- </ng-container> -->

        </div>
      </ng-template>

    </div>
    <!-- <ng-template #infoDialog> -->
    <div mat-dialog-content id="yesInfo" class="info-content">
      <app-info-page class="info-page-component"></app-info-page>
    </div>
    <!-- </ng-template> -->

    <mat-dialog-actions class="col-12 d-flex justify-content-center align-items-center">

      <!-- <button fxFlex="22" mat-raised-button color="primary" (click)="save()">Save</button> -->
      <!-- <button fxFlex="22" mat-raised-button color="accent" (click)="close()">Cancel</button> -->
      <button class="col-2" mat-raised-button color="accent" (click)="close()">Cancel</button>

    </mat-dialog-actions>
  </div>

</form>
<!-- {{form.get('statisticSel')?.value}}
{{statistic | json}} -->
