<form [formGroup]="form" style="width: 500px;">

  <div mat-dialog-content class="col-12">
    <div class="d-flex flex-row justify-content-between align-items-center pb-1">
      <h1 mat-dialog-title class="d-flex flex-row justify-content-center align-items-center pb-0"><span class="info-title-dialog bolder">Filters</span></h1>
      <!-- <button type="button" class="btn btn-xs btn-primary">Reset</button> -->
      <button mat-raised-button color="primary" (click)="resetFilters()">Reset</button>
    </div>

    <div class="row">
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Type</mat-label>
        <!-- <mat-select formControlName="firstDataset" (selectionChange)="getSelectedVarFirstDataset(); checkDimensions()"> -->
        <mat-select formControlName="category" (selectionChange)="changeSel('c')" multiple>
          <mat-option *ngFor="let c of categoryDatasets" [value]="c">
            {{c}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="row">
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Scale</mat-label>
        <!-- <mat-select formControlName="dataSetSel" (selectionChange)="selActiveLayer($event)"> -->
        <mat-select formControlName="scale" (selectionChange)="changeSel('s')" multiple>
          <mat-option *ngFor="let s of scaleDatasets" [value]="s">
            {{s}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="row">
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Time period</mat-label>
        <mat-select formControlName="timeperiod" (selectionChange)="changeSel('t')" multiple>
          <mat-option *ngFor="let t of timeperiodDatasets" [value]="t">
            {{t}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="row">
      <div class="d-flex flex-row justify-content-start align-items-center pb-1">
        <h1 mat-dialog-title class="d-flex flex-row justify-content-center align-items-center pb-0"><span class="info-title-dialog bolder">Select dataset</span></h1>
        <button *ngIf="form.get('menu')?.value" style="background-color: #F0F0F0;"
            class="border p-2 btn btn-xs btn-icon d-flex flex-row justify-content-center align-items-center"
            (click)="openTableDialog()" matTooltip="Info about the layer">
            <!-- matTooltip="Info about the layer"> -->
          <mat-icon [ngStyle]="{'color': 'blue'}" class="col-12">info</mat-icon>
        </button>

      </div>
    </div>
    <div class="row">
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Dataset</mat-label>
        <!-- <mat-select #quartoMatSelect formControlName="menu" (openedChange)="changeHeight(); setFilter()" class="d-flex"> -->
        <mat-select #quartoMatSelect formControlName="menu" (openedChange)="setFilter()" class="d-flex">
          <div>

            <div class="col-12 d-flex flex-row justify-content-center align-items-center">
              <mat-form-field class="example-full-width" color="primary" appearance="outline">
                <mat-label>Filter</mat-label>
                <input type="text" matInput #searchInput (keyup)="onKey($event)">
                <button matSuffix mat-icon-button aria-label="Clear">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>

            </div>
            <mat-option *ngFor="let m of searchFilter" [value]="m">
              {{m.title}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>

    </div>

  </div>

  <mat-dialog-actions class="row justify-content-center">

    <button mat-raised-button color="primary" class="col-3" (click)="ok()">Ok</button>
    <button mat-raised-button color="accent" class="col-3" (click)="close()">Cancel</button>

  </mat-dialog-actions>


</form>
