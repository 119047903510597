<form [formGroup]="form" style="width: 500px;">

    <h1 mat-dialog-title><span class="info-title-dialog">Compare Two Datasets</span></h1>
      <div mat-dialog-content class="col-12">

        <div class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Select first dataset</mat-label>
            <!-- <mat-select formControlName="dataSetSel" (selectionChange)="selActiveLayer($event)"> -->
            <mat-select formControlName="firstDataset" (selectionChange)="getSelectedVarFirstDataset(); checkDimensions()">
              <mat-option *ngFor="let a of activeLayersArray" [value]="a">
                {{a.name.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Select variable</mat-label>
            <!-- <mat-select formControlName="dataSetSel" (selectionChange)="selActiveLayer($event)"> -->
            <mat-select formControlName="variableFirstData">
              <mat-option *ngFor="let a of firstDatasetVariables" [value]="a.name">
                {{a.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div class="row justify-content-center align-items-center" style="margin: 10px 0px 5px 0px; height: fit-content;" *ngIf="this.form.get('firstDataset')?.value.name.dimensions > 3">
          <form [formGroup]="sliderForm" class="col-12 d-flex flex-column justify-content-center align-items-center p-0">
            <div class="example-label-container col-9 d-flex flex-row justify-content-center align-items-center">
              <label id="example-name-label" class="example-name-label col-12 d-flex justify-content-center align-items-center">{{extraParamFirst.name}}</label>
            </div>
            <ngx-slider [(value)]="firstValue" [options]="firstOptions" (userChangeEnd)="changeValueSliderOne($event)"></ngx-slider>

          </form>
        </div>

        <div class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Select second dataset</mat-label>
            <!-- <mat-select formControlName="dataSetSel" (selectionChange)="selActiveLayer($event)"> -->
            <mat-select formControlName="secondDataset" (selectionChange)="getSelectedVarSecondDataset(); checkDimensions()">
              <mat-option *ngFor="let a of activeLayersArray" [value]="a">
                {{a.name.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Select variable</mat-label>
            <!-- <mat-select formControlName="dataSetSel" (selectionChange)="selActiveLayer($event)"> -->
            <mat-select formControlName="variableSecondData">
              <mat-option *ngFor="let a of secondDatasetVariables" [value]="a.name">
                {{a.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div class="row justify-content-center align-items-center" style="margin: 10px 0px 5px 0px; height: fit-content;" *ngIf="this.form.get('secondDataset')?.value.name.dimensions > 3">
          <form class="col-12 d-flex flex-column justify-content-center align-items-center p-0">
            <div class="example-label-container col-9 d-flex flex-row justify-content-center align-items-center">
              <label id="example-name-label" class="example-name-label col-12 d-flex justify-content-center align-items-center">{{extraParamSecond.name}}</label>
            </div>
            <ngx-slider [(value)]="secondValue" [options]="secondOptions" (userChangeEnd)="changeValueSliderSecond($event)"></ngx-slider>

          </form>
        </div>

      </div>

      <!-- <mat-dialog-actions fxLayoutAlign="center center"> -->
      <mat-dialog-actions class="row justify-content-center">

        <button mat-raised-button color="primary" class="col-3" (click)="compareDatasets()">Ok</button>
        <button mat-raised-button color="accent" class="col-3" (click)="close()">Cancel</button>

      </mat-dialog-actions>
    <!-- </div> -->


  </form>
