<div class="main-content col-12">

  <div class="col-12">
    <mat-toolbar color="primary">

      <div class="d-flex justify-content-center align-items-center gap-5 col-12 toolbar-buttons h-75">

        <button class="col-2" mat-raised-button (click)="goToMap()" style="color: #fff; background-color: rgba(0, 221, 221, 0.795);">
          <mat-icon>public</mat-icon> <span class="text-menu"> Geoportal map </span>
        </button>

      </div>

    </mat-toolbar>
  </div>
  <div class="container">
    <div class="row justify-content-between pt-3">

      <div class="col-2 d-flex justify-content-center align-items-center">
        <button class="col btn btn-primary" (click)="goToWebProject()">Web project</button>
      </div>

      <!-- <div class="col arrow"></div> -->

      <div class="col-2 d-flex justify-content-center align-items-center">
        <button class="col btn btn-primary" (click)="goToToolkit()">Toolkit</button>
      </div>

    </div>
    <div class="row">
      <!-- <h2 class="text-center">Geoportal Adriaclim</h2> -->
      <h2 class="text-center col">{{welJson.title}}</h2>
    </div>
    <div class="row">
      <!-- <p class="col">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam quis odio ante. Nulla congue elementum laoreet. Pellentesque id ipsum leo. Etiam non arcu neque. Praesent sapien libero, venenatis at enim dictum, fringilla iaculis nibh. Vestibulum laoreet augue posuere arcu ultrices vehicula. Cras vel metus et ligula faucibus consectetur quis in dui. Fusce venenatis lorem id quam sagittis, et facilisis velit egestas. Etiam ac laoreet sem. Nullam luctus, ante a sodales euismod, ante ligula scelerisque libero, sed aliquet nunc massa eget eros. Morbi purus est, semper in eleifend eget, consequat sollicitudin erat. Donec aliquet, ex sit amet tincidunt mattis, quam tortor maximus nunc, at efficitur dolor neque id nunc.

        Sed velit sapien, dapibus quis lacinia ut, volutpat nec ante. Aenean aliquet pulvinar elit, sed lacinia odio finibus ut. Phasellus venenatis, felis vel commodo tincidunt, tellus est cursus ipsum, et finibus ex nunc vitae libero. Donec nec tincidunt lectus. Proin id elit semper, finibus lectus vel, tempus elit. Morbi enim odio, pretium ornare pellentesque ac, placerat non mauris. Duis ac cursus libero. Ut semper feugiat congue. Sed gravida vestibulum dolor, nec accumsan metus dictum in. Nunc ullamcorper diam vel ipsum blandit tincidunt. Praesent accumsan enim in congue gravida. Vestibulum gravida at metus ut gravida. Sed tortor turpis, lobortis ac lectus in, sagittis euismod lacus.

        Sed et feugiat eros. Fusce id nisl porttitor, pretium lectus vel, pulvinar velit. Cras eleifend ullamcorper enim. Ut aliquet sem volutpat maximus maximus. Donec imperdiet hendrerit tortor, sit amet dignissim nulla pretium venenatis. Ut sit amet rhoncus turpis. In sodales arcu urna, consectetur sollicitudin erat dapibus quis. Mauris eget leo iaculis, rhoncus elit ac, fringilla nunc.

        Nullam ut lectus efficitur, aliquam elit et, ultrices magna. Integer a mi lacus. Aliquam dui erat, rhoncus vitae odio nec, aliquet tempus turpis. Curabitur vel fermentum nisl. Morbi aliquam arcu et cursus.

        Generated 4 paragraphs, 300 words, 2000 bytes of Lorem Ipsum
      </p> -->
      <p class="col">
        <!-- {{welJson.text}} -->
      </p>
    </div>

  </div>

</div>
