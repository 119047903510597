<style>
  .font-weight-bold {
    font-weight: bold;
  }
</style>
<div class="main-content col-12">

  <div class="col-12">
    <mat-toolbar color="primary" class="col-12">

      <!-- <div class="d-flex justify-content-center align-items-center gap-5 col-12 toolbar-buttons h-75"> -->
      <div class="row justify-content-center align-items-center col-12 toolbar-buttons h-75">

        <button class="col-1 col-md-2 col-lg-1" mat-raised-button routerLink="/"
          style="color: #fff; background-color: #0ba7d7;">
          <mat-icon>arrow_back</mat-icon> <span class="text-menu"> Welcome </span>
        </button>
        <div class="d-flex justify-content-center align-items-center gap-5 col col-md toolbar-buttons h-100">

          <!-- BOTTONE CON VECCHIO COLORE -->
          <!-- <button class="col-2 col-md-3 col-lg-2" mat-raised-button
            style="color: #fff; background-color: rgba(0, 221, 221, 0.795);" #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="myMenu2"> <mat-icon> list_alt</mat-icon> <span class="text-menu"> Full list </span>
          </button> -->

          <!-- BOTTONE CON NUOVO COLORE -->
          <button class="col-2 col-md-3 col-lg-2" mat-raised-button style="color: #fff; background-color: #0ba7d7;"
            #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="myMenu2"> <mat-icon> list_alt</mat-icon> <span
              class="text-menu"> Full list </span>
          </button>

          <!-- <button fxFlex="20" mat-raised-button style="color: #fff; background-color: rgba(0, 221, 221, 0.795);" -->

          <!-- *************************** LAVORIAMO QUI *************************** -->
          <!-- <button class="col-2 col-md-3 col-lg-2" mat-raised-button
            style="color: #fff; background-color: rgba(0, 221, 221, 0.795);" #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="myMenu1"> <mat-icon>public</mat-icon> <span class="text-menu"> Dataset </span>
          </button> -->

          <!-- BOTTONE CON VECCHIO COLORE -->
          <!-- <button type="button" class="dropdown-toggle col-2 col-md-3 col-lg-2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
            mat-raised-button style="color: #fff; background-color: rgba(0, 221, 221, 0.795);">
            <mat-icon>public</mat-icon> <span class="text-menu"> Dataset </span>
          </button> -->

          <!-- BOTTONE CON NUOVO COLORE -->
          <button type="button" class="dropdown-toggle col-2 col-md-3 col-lg-2" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="true" mat-raised-button style="color: #fff; background-color: #0ba7d7;">
            <mat-icon>public</mat-icon> <span class="text-menu"> Dataset </span>
          </button>

          <div class="dropdown-menu col-12 dark mt-auto" style="z-index: 2000; max-height: 600px;" (click)="$event.stopPropagation()">
            <form [formGroup]="formMenuDatasets" class="col-12" style="max-height: 600px;">
              <div class="link-list-wrapper col d-flex flex-row justify-content-center align-items-start ps-1 pe-3" style="max-height: 600px;">

                <div class="col d-flex flex-column justify-content-center align-items-center">
                  <button class="btn btn-primary" type="button" (click)="checkExpand('first')">
                    Type
                  </button>
                  <div class="card card-body col-12"
                    [ngClass]="{'show': isExpandedFirst, 'collapse': !isExpandedFirst}">
                    <label *ngFor="let c of categoryDatasets"
                      class="col-12 d-flex justify-content-center align-items-start flex-column dropdown-item list-item">
                      <div class="d-flex justify-content-center align-items-center flex-row gap-1">
                        <input type="radio" [value]="c" name="category" formControlName="category"
                          (change)="changeSel(c, 'c')" class="edit-radio-button-circle">
                        <span>{{c}}</span>
                      </div>
                    </label>
                  </div>
                </div>

                <div class="col d-flex flex-column justify-content-center align-items-center">
                  <button class="btn btn-primary" type="button" (click)="checkExpand('second')">
                    Scale
                  </button>
                  <div class="card card-body col-12"
                    [ngClass]="{'show': isExpandedSecond, 'collapse': !isExpandedSecond}">
                    <label *ngFor="let s of scaleDatasets"
                      class="col-12 d-flex justify-content-center align-items-start flex-column dropdown-item list-item">
                      <div class="d-flex justify-content-center align-items-center flex-row gap-1">
                        <input type="radio" [value]="s" name="scale" formControlName="scale"
                          (change)="changeSel(s, 's')" class="edit-radio-button-circle">
                        <span>{{s}}</span>
                      </div>
                    </label>
                  </div>
                </div>

                <div class="col d-flex flex-column justify-content-center align-items-center">
                  <button class="btn btn-primary" type="button" (click)="checkExpand('third')">
                    Time period
                  </button>
                  <div class="card card-body col-12"
                    [ngClass]="{'show': isExpandedThird, 'collapse': !isExpandedThird}">
                    <label *ngFor="let t of timeperiodDatasets"
                      class="col-12 d-flex justify-content-center align-items-start flex-column dropdown-item list-item">
                      <div class="d-flex justify-content-center align-items-center flex-row gap-1">
                        <input type="radio" [value]="t" name="timeperiod" formControlName="timeperiod"
                          (change)="changeSel(t, 't')" class="edit-radio-button-circle">
                        <span>{{t}}</span>
                      </div>
                    </label>
                  </div>
                </div>

                <div class="col d-flex flex-column justify-content-center align-items-center"
                  style="max-width: 400px !important; max-height: 600px;">
                  <button class="btn btn-primary" type="button" (click)="checkExpand('fourth')">
                    Datasets
                  </button>
                  <div class="card card-body col-10"
                    [ngClass]="{'show': isExpandedFourth, 'collapse': !isExpandedFourth}" style="max-height: 500px; overflow: auto;">
                    <label *ngFor="let d of menuDatasets"
                      class="col-10 d-flex justify-content-center align-items-start flex-column dropdown-item list-item"
                      style="width: fit-content; max-width: 400px;">
                      <div class="col-12 d-flex justify-content-center align-items-center flex-row gap-1">
                        <input type="radio" [value]="d" name="dataset" formControlName="dataset"
                          class="col-1 edit-radio-button-circle" (change)="selDatasetProva(d)">
                        <span class="col"
                          style="max-width: 400px !important; word-break: break-all !important; white-space: normal;">{{d.title}}</span>
                      </div>
                    </label>
                  </div>
                </div>



                <!-- <div class="form-check" *ngFor="let c of categoryDatasets">
                  <input id="checkbox1" type="checkbox" formControlName="">
                  <label for="checkbox1">Checkbox di esempio</label>
                </div> -->

                <!-- <label for="check" formArrayName="orders" *ngFor="let order of ordersFormArray.controls; let i = index">

                  <input id="check" type="checkbox" [formControlName]="i">

                  {{webData[i].name}}

                </label> -->




              </div>
            </form>
          </div>

          <form [formGroup]="selData">
            <mat-radio-group formControlName="dataSetSel">

              <mat-menu #myMenu2="matMenu" class="menu-full-list">
                <ng-template matMenuContent>
                  <!-- <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season"> -->
                  <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center start"> -->
                  <div class="col-12 d-flex flex-column h-100 justify-content-center align-items-start">
                    <!-- <div fxFlex style="width: 100%;" fxLayout="row" fxLayoutAlign="center center" -->
                    <div class="d-flex flex-row col-12 justify-content-center align-items-center"
                      (click)="$event.stopPropagation();">
                      <mat-form-field class="example-full-width" color="primary" appearance="outline">
                        <!-- <mat-label fxFlex="80">Filter title or institution</mat-label> -->
                        <mat-label>Filter title or institution</mat-label>
                        <!-- <mat-icon fxFlex="20" fxLayoutAlign="end center">search</mat-icon> -->
                        <input type="text" matInput formControlName="searchText">
                        <button matSuffix mat-icon-button aria-label="Clear">
                          <mat-icon>search</mat-icon>
                        </button>
                      </mat-form-field>
                      <!-- {{selData.get("searchText")?.value | json}} -->
                    </div>
                    <ng-container *ngFor="let n of dataAllNodes | appFilter: selData.get('searchText')?.value">
                      <!-- <ng-container *ngFor="let n of dataAllNodes | highlight: selData.get('searchText')?.value"> -->
                      <!-- <div fxFlex fxLayout="row" fxLayoutAlign="center center"> -->
                      <div class="d-flex flex-row justify-content-center align-items-center col-12">
                        <!-- <mat-radio-button fxFlex class="example-radio-button"  appHighlight [searchedWord]="selData.get('searchText')?.value" [content]="n" [classToApply]="'font-weight: bold'" [setTitle]="true" [value]="n" (change)="getMeta(n.name.id); getSelectedNode(n)">
                        {{n.name.title}}
                      </mat-radio-button> -->
                        <!-- <mat-radio-button fxFlex class="example-radio-button" [value]="n" -->
                        <mat-radio-button class="example-radio-button col-12" [value]="n"
                          (change)="getMeta(n.name.id); addToActiveLayers(n); getSelectedNode(n); ">
                          {{n.name.title}}
                        </mat-radio-button>
                      </div>
                      <mat-divider style="width: 100%; margin: 10px 0px;"></mat-divider>

                    </ng-container>

                  </div>
                </ng-template>

              </mat-menu>

              <mat-menu #myMenu1="matMenu" class="menu">
                <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center start"> -->
                <div class="d-flex flex-column justify-content-center align-items-start">
                  <!-- <div fxFlex style="width: 100%;" fxLayout="row" fxLayoutAlign="center center" -->
                  <div class="d-flex flex-row justify-content-center align-items-center col-12"
                    (click)="$event.stopPropagation();">
                    <mat-form-field class="example-full-width" color="primary" appearance="outline">
                      <!-- <mat-label fxFlex="100">Filter title or institution</mat-label> -->
                      <mat-label>Filter title or institution</mat-label>
                      <!-- <mat-icon fxFlex="20" fxLayoutAlign="end center">search</mat-icon> -->
                      <input type="text" matInput formControlName="searchTextDataset">
                      <button matSuffix mat-icon-button aria-label="Clear">
                        <mat-icon>search</mat-icon>
                      </button>
                    </mat-form-field>
                    <!-- {{selData.get("searchText")?.value | json}} -->
                  </div>
                </div>
                <ng-template matMenuContent>

                  <mat-tree [dataSource]="dataAllNodesTree" [treeControl]="treeControl">
                    <mat-tree-node *matTreeNodeDef="let node; let lastItem = last" matTreeNodePadding>
                      <!-- {{node | json}} -->
                      <!-- <ng-container *ngIf="node.expandable === false && node.level > 2; else noRadio"> -->
                      <ng-container *ngIf="node.expandable === false && !isAString(node.name); else noRadio">
                        <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center start"
                        style="max-width: none !important; width: fit-content;"> -->
                        <div class="d-flex flex-column justify-content-center align-items-start w-100"
                          style="max-width: none !important;">
                          <!-- <div fxFlex fxLayout="row" fxLayoutAlign="center center" -->
                          <div class="d-flex flex-row justify-content-start align-items-center col-12 w-100"
                            style="max-width: none !important;">
                            <button (click)="openTableDialog(node.name['id'], node.name['title'], node)"
                              matTooltip="Info about the layer" matTooltipPosition="left"
                              style="background-color: #F0F0F0;"
                              class="col-1 border btn btn-xs btn-icon ms-1 d-flex flex-row justify-content-center align-items-center">
                              <mat-icon class="col-12" [ngStyle]="{'color': 'blue'}">info</mat-icon>
                            </button>
                            <mat-radio-button [value]="node"
                              (change)="getMeta(node.name['id']); getSelectedNode(node); addToActiveLayers(node)">{{node.name["title"]}}</mat-radio-button>

                          </div>
                          <mat-divider style="width: 100%; margin: 10px 0px;" *ngIf="!lastItem"></mat-divider>

                        </div>
                      </ng-container>

                      <ng-template #noRadio>
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                        {{node.name}}
                      </ng-template>

                    </mat-tree-node>

                    <!-- <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                      </mat-icon>
                    </button>
                    {{node.name}}

                  </mat-tree-node> -->

                  </mat-tree>

                  <!-- <button mat-menu-item>A 1</button>
                <button mat-menu-item>A 2</button>
                <button mat-menu-item>A 3</button> -->
                </ng-template>
              </mat-menu>
            </mat-radio-group>
          </form>

          <!-- <button fxFlex="20" mat-raised-button color="warn"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="myMenu2"> <span class="text-menu"> Variable </span></button> -->
          <!-- (mouseenter)="openMyMenu(menuTrigger)" -->

          <!-- <div fxFlex="20" fxLayout="row" class="menu-var" LayoutAlign="center center"> -->
          <div class="menu-var col-2 row" LayoutAlign="center center">
            <form [formGroup]="variableGroup">
              <ng-container *ngIf="variableArray.length > 0; else noVariable">
                <!-- <ng-container *ngFor="let v of variableArray; let lastItem = last">

                <button mat-menu-item>{{v}}</button>
                <mat-divider *ngIf="!lastItem"></mat-divider>
              </ng-container> -->

                <mat-form-field appearance="fill" class="prova-form">
                  <mat-label>Select variable</mat-label>
                  <mat-select formControlName="variableControl" class="no-border-focus"
                    (selectionChange)="getMeta(selData.get('dataSetSel')?.value.name.id, 'ok')">
                    <mat-option *ngFor="let v of variableArray" [value]="v.name" class="prova-option">
                      {{v.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <ng-template #noVariable>
                <!-- <span>First select dataset</span> -->
                <mat-form-field appearance="fill">
                  <mat-label>First select dataset</mat-label>
                  <mat-select>
                    <mat-option>
                      First select dataset
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-template>

            </form>

          </div>
        </div>

      </div>

    </mat-toolbar>
  </div>

  <!-- Alert tramite bootstrap con valore booleano controllato nel typescript -->
  <div *ngIf="showAlert" class="alert alert-warning alert-dismissible w-25 m-auto mt-2" role="alert"
    style="z-index: 1500;">
    {{compliantErrorErddap}}
    <button type="button" class="btn-close" (click)="showAlert = false" aria-label="Close"></button>
  </div>

  <!-- MAT MENU PER TOOLS -->
  <!-- <div fxFlex="fit-content" fxLayout="row" fxLayoutAlign="center center" class="overlay-tools"> -->
  <div class="overlay-tools">
    <!-- <button fxFlex="20" mat-raised-button style="color: #fff; background-color: rgba(0, 221, 221, 0.795);" -->
    <button class="col-12" mat-raised-button style="color: #fff; background-color: rgba(0, 221, 221, 0.795);"
      #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="myMenuTools">
      <mat-icon> list_alt</mat-icon>
      <span class="text-menu"> Tools </span>
    </button>

    <mat-menu #myMenuTools="matMenu" class="menu-tools">
      <ng-template matMenuContent>
        <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center start"> -->
        <div class="d-flex flex-column justify-content-center align-items-start">
          <div>
            <button mat-icon-button matTooltip="Select a single point on the map" matTooltipPosition="right"
              (click)="pointSelect()"><mat-icon> place</mat-icon></button>
          </div>
          <div>
            <button mat-icon-button matTooltip="Select all points in one of the areas shown" (click)="polygonSelect()"
              matTooltipPosition="right"><mat-icon>touch_app</mat-icon></button>
          </div>
          <div>
            <button mat-icon-button matTooltip="Select a single point by coordinates" (click)="openModalSelectCoords()"
              matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
          </div>

        </div>
      </ng-template>

    </mat-menu>
  </div>
  <!-- MAT MENU PER SCELTA POLIGONI -->
  <!-- <div fxFlex="fit-content" fxLayout="row" fxLayoutAlign="center center" class="overlay-polygon"> -->
  <div class="overlay-polygon">
    <!-- <button fxFlex="20" mat-raised-button style="color: #fff; background-color: rgba(0, 221, 221, 0.795);" -->
    <button class="col-12" mat-raised-button style="color: #fff; background-color: rgba(0, 221, 221, 0.795);"
      #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="myMenuPolygon">
      <mat-icon> list_alt</mat-icon>
      <span class="text-menu"> Areas </span>
    </button>

    <!--Modifichiamo dopo!-->
    <mat-menu #myMenuPolygon="matMenu" class="polygon-tools">
      <ng-template matMenuContent>
        <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center start"> -->
        <div class="d-flex flex-column justify-content-center align-items-start">
          <div>
            <button mat-button matTooltip="Select the Adriatic view" matTooltipPosition="right" (click)="adriaticView()"
              class="font-bad-script">Adriatic View</button>
          </div>
          <div>
            <button mat-button matTooltip="Select the Pilot view" matTooltipPosition="right" (click)="pilotView()"
              class="font-bad-script">Pilot View</button>
          </div>
          <div>
            <button mat-button matTooltip="Upload a GeoJSON with your polygons" matTooltipPosition="right"
              (click)="uploadGeo()" class="font-bad-script">Upload a GeoJSON</button>
          </div>
          <div>
            <button mat-button matTooltip="Upload a GeoJSON with your polygons" matTooltipPosition="right"
              (click)="removeAllPolygons()" class="font-bad-script">Remove all</button>
          </div>

        </div>
      </ng-template>

    </mat-menu>
  </div>

  <ng-container *ngIf="selData.get('dataSetSel')?.value">
    <!-- <div fxFlex="fit-content" fxLayout="row" fxLayoutAlign="center center" class="overlay-buttons-top-map"> -->
    <div class="overlay-buttons-top-map">
      <!-- <mat-card class="dataset-panel mat-card-active" fxFlex fxLayout="column" fxLayoutAlign="start center"> -->
      <mat-card class="dataset-panel mat-card-active d-flex flex-column justify-content-start align-items-center">
        <mat-card-header>
          <mat-card-title class="bolder-title">Active layer</mat-card-title>
        </mat-card-header>
        <!-- <mat-card-content style="height: 100%;" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" class="mat-card-active"> -->
        <mat-card-content style="height: 100%;" class="mat-card-active row p-0 col-12">
          <!-- <div fxFlex="80" style="height: 100%; overflow: auto;"> -->
          <!-- <div fxFlex="90" fxLayout="row" fxLayoutAlign="space-evenly center" class="edit-icon-size" fxLayoutGap="10px"> -->
          <!-- <div class="d-flex flex-row justify-content-start align-items-center col-12 edit-icon-size gap-3"> -->
          <div class="row justify-content-start">

            <!-- <div fxFlex fxLayout="row" class="menu-var" fxLayoutAlign="center center"> -->
            <div class="menu-var d-flex flex-row justify-content-center align-items-center col">
              <!-- <form [formGroup]="selData" fxFlex> -->
              <form [formGroup]="selData" class="col-12">

                <!-- <mat-form-field appearance="fill" fxFlex> -->
                <mat-form-field appearance="fill" class="col-12">
                  <mat-label>Select Layers</mat-label>
                  <!-- <mat-select formControlName="activeLayersControl" (selectionChange)="getMeta(activeLayersGroup.get('activeLayersControl')?.value.name.dataset_id, 'ok')"> -->
                  <mat-select formControlName="dataSetSel" (selectionChange)="selActiveLayer($event)">
                    <mat-option *ngFor="let a of activeLayersArray" [value]="a">
                      {{a.name.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </form>

            </div>
            <!-- <div fxFlex="25" fxLayout="row" fxLayoutAlign="space-evenly center"> -->
            <div class="d-flex flex-row justify-content-center align-items-center col-4 gap-1">
              <div
                [matTooltip]="activeLayersArray.length < 2 ? 'Please select two datasets for compare' : 'Compare two datasets'">
                <button style="background-color: #F0F0F0;"
                  class="border p-2 btn btn-xs btn-icon d-flex flex-row justify-content-center align-items-center"
                  (click)="compareDialogModal()" [disabled]="activeLayersArray.length < 2">
                  <mat-icon class="col-12">compare_arrows</mat-icon>
                </button>

              </div>
              <button style="background-color: #F0F0F0;"
                class="border p-2 btn btn-xs btn-icon d-flex flex-row justify-content-center align-items-center"
                (click)="openTableDialog()" matTooltip="Info about the layer">
                <mat-icon [ngStyle]="{'color': 'blue'}" class="col-12">info</mat-icon>
              </button>
              <button style="background-color: #F0F0F0;"
                class="border p-2 btn btn-xs btn-icon d-flex flex-row justify-content-center align-items-center"
                (click)="deleteLayer(); deleteElActiveLayers()" matTooltip="Remove the layer">
                <mat-icon [ngStyle]="{'color': 'red'}" class="col-12">delete</mat-icon>
              </button>
              <!-- <button fxFlex="40" matTooltip="Remove the layer" class="no-border-button-icon"><mat-icon [ngStyle]="{'color': 'red'}" -->
              <!-- <button (click)="deleteLayer(); deleteElActiveLayers()" matTooltip="Remove the layer" class="border btn btn-xs btn-icon ms-1 col-6 d-flex flex-row justify-content-center align-items-center"><mat-icon [ngStyle]="{'color': 'red'}"
                  >delete</mat-icon>
              </button> -->

            </div>
          </div>

          <!-- <div fxFlex="90" style="margin: 10px 0px 5px 0px;" fxLayout="row" fxLayoutAlign="center center"> -->
          <div class="d-flex flex-row justify-content-between align-items-center col-12"
            style="margin: 10px 0px 5px 0px;">
            <!-- <form [formGroup]="selectedDate" fxFlex="50"> -->
            <form [formGroup]="selectedDate" class="col-6">
              <!-- <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" class="form-datepicker"> -->
              <div class="form-datepicker d-flex flex-row justify-content-center align-items-center col-12">
                <!-- <mat-form-field appearance="fill" fxFlex fxLayout="row" fxLayoutAlign="center center"> -->
                <mat-form-field appearance="fill"
                  class="d-flex flex-row justify-content-center align-items-center col-12">
                  <mat-label>DD/MM/YYYY</mat-label>
                  <!-- <input matInput [matDatepicker]="datepicker" [matDatepickerFilter]="dateFilter" [min]="dateStart"
                    [max]="dateEnd" formControlName="dateSel" fxFlex> -->
                  <input matInput [matDatepicker]="datepicker" [matDatepickerFilter]="dateFilter" [min]="dateStart"
                    [max]="dateEnd" formControlName="dateSel" class="col-12">
                  <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->
                  <!-- <mat-datepicker-toggle fxFlex matIconSuffix [for]="datepicker"></mat-datepicker-toggle> -->
                  <mat-datepicker-toggle class="col-12" matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                  <mat-datepicker #datepicker>
                    <mat-datepicker-actions>
                      <button mat-button matDatepickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDatepickerApply
                        (click)="getMeta(selData.get('dataSetSel')?.value.name.id, 'ok')">Apply</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>

              </div>
            </form>
            <!-- <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center"> -->
            <div class="d-flex flex-row justify-content-center align-items-center col-6">
              <!-- DA FARE!! -->
              <!-- <button mat-icon-button fxFlex (click)="changeDate('leftAll')"><mat-icon>first_page</mat-icon></button> -->
              <button mat-icon-button class="col-12"
                (click)="changeDate('leftAll')"><mat-icon>first_page</mat-icon></button>
              <!-- dateStart: {{dateStart}} dateEnd: {{dateEnd}} -->
              <!-- <button mat-icon-button fxFlex (click)="changeDate('left')" -->
              <button mat-icon-button class="col-12" (click)="changeDate('left')"
                [disabled]="navigateDateLeftYear || navigateDateLeftMonth || navigateDateLeftSeason || dateStart?.toString() === dateEnd?.toString()"><mat-icon>navigate_before</mat-icon></button>
              <!-- <button mat-icon-button fxFlex (click)="changeDate('right')" -->
              <button mat-icon-button class="col-12" (click)="changeDate('right')"
                [disabled]="navigateDateRightYear || navigateDateRightMonth || navigateDateRightSeason || dateStart === dateEnd"><mat-icon>navigate_next</mat-icon></button>
              <!-- <button mat-icon-button fxFlex (click)="changeDate('rightAll')"><mat-icon>last_page</mat-icon></button> -->
              <button mat-icon-button class="col-12"
                (click)="changeDate('rightAll')"><mat-icon>last_page</mat-icon></button>

            </div>
          </div>

          <!-- <div fxFlex="90" style="margin: 10px 0px 5px 0px; height: fit-content;" fxLayout="column" fxLayoutAlign="center center" *ngIf="isExtraParam"> -->
          <div class="col-12 d-flex flex-column justify-content-center align-items-center"
            style="margin: 10px 0px 5px 0px; height: fit-content;" *ngIf="isExtraParam">
            <!-- <form [formGroup]="sliderGroup" fxFlex fxLayout="column" style="width: 100%;" fxLayoutAlign="center center"> -->
            <form [formGroup]="sliderGroup" class="col-12 d-flex flex-column justify-content-center align-items-center">
              <!-- <div class="example-label-container" style="width: 80%;" fxLayout="row" fxLayoutAlign="center center"> -->
              <div class="example-label-container col-9 d-flex flex-row justify-content-center align-items-center">
                <!-- <label fxFlex fxLayoutAlign="center center" id="example-name-label" class="example-name-label">{{extraParam.name}}</label> -->
                <label id="example-name-label"
                  class="example-name-label col-12 d-flex justify-content-center align-items-center">{{extraParam.name}}</label>
              </div>
              <!-- [disabled]="disabled" -->
              <ngx-slider [(value)]="value" [options]="options" (userChangeEnd)="sliderControl($event)"></ngx-slider>

            </form>
          </div>
          <!-- </div> -->
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>

  <!-- <div fxFlex="fit-content" fxLayout="row" fxLayoutAlign="center center" class="overlay-image"> -->
  <div class="overlay-image col-auto d-flex flex-row justify-content-center align-items-center"
    style="max-height: fit-content;">
    <ng-container *ngIf="this.selData.get('dataSetSel')?.value">
      <!-- <ng-container *ngIf="legendLayer_src; else legendTabledap"> -->
      <ng-container *ngIf="legendLayer_src">
        <img [src]="legendLayer_src" style="height: 100px;">

      </ng-container>
      <!-- <ng-template #legendTabledap>
        <img src="https://upload.wikimedia.org/wikipedia/commons/c/cf/Google_URL_Shortener_Logo.png">

      </ng-template> -->

    </ng-container>

  </div>

  <!-- START MAP -->
  <div class="map-container">
    <div class="map-frame">
      <div id="map"></div>
    </div>
  </div>
  <!-- END MAP -->
</div>

<!-- <p>Selection: {{formMenuDatasets.value | json}}</p> -->
